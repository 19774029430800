import React from "react";
import config from "../assets/index.json";
import { motion } from "framer-motion";

const image = {
  judgeHarmer: require("../assets/svg/judge-harmer.svg").default,
};

const schedule = () => {
  const { schedule } = config;
  const { title, description, img, buttonContent } = schedule;
  return (
    <div className="w-full h-full">
      <div className="flex justify-center items-center w-full h-20">
        <b className="h-8 sm:h-14 text-secondary font-Cormorant text-2xl sm:text-4xl border-b border-b-2 border-primary">
          {title}
        </b>
      </div>
      <div className="grid grid-cols-2 sm:grid-cols-4 3xl:grid-cols-5 grid-rows-auto lg:mt-10 h-full">
        <div className="sm:col-span-2 3xl:col-span-3 flex flex-row justify-end items-center h-full w-full">
          <img src={image[img]} className="h-full" alt={img} />
        </div>
        <div className="p-2 sm:p-20 3xl:p-20 sm:col-span-2 3xl:col-span-2 h-full flex items-center justify-center">
          <div className="flex flex-col justify-around items-center h-full">
            <b className="font-poppins sm:text-xl lg:text-4xl">{description}</b>
            <motion.button
              className="bg-primary border border-1 border-tertiary w-full h-14 sm:h-16 text-lg lg:text-2xl text-fourth font-poppins font-bold rounded-lg"
              whileHover={{ scale: 1.05 }}
            >
              <a target="_black" href={buttonContent.href}>
                {buttonContent.description}
              </a>
            </motion.button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default schedule;
