import React from "react";
import config from "../assets/index.json";

const icons = {
  logo: require("../assets/svg/camillo-adv-icon.svg").default,
  cellphoneIcon: require("../assets/svg/cellphone-icon.svg").default,
  mailIcon: require("../assets/svg/mail-icon.svg").default,
  linkedinIcon: require("../assets/svg/linkedin-icon.svg").default,
  instagramIcon: require("../assets/svg/instagram-icon.svg").default,
  whatsappIcon: require("../assets/svg/whatsapp-icon.svg").default,
  facebookIcon: require("../assets/svg/facebook-icon.svg").default,
};

const address = (logo, addressInfos) => {
  return (
    <div className="flex flex-row h-full w-full items-center justify-center">
      <div className="w-1/4 sm:w-1/3 flex justify-end p-2">
        <img src={icons[logo]} alt="camilloAdv" className="" />
      </div>
      <div className="flex flex-col w-3/4 sm:w-2/3 p-2 justify-center h-full text-fourth font-poppins font-semibold text-sm lg:text-md">
        <p>{`${addressInfos.street}, ${addressInfos.number}`}</p>
        <p>{`${addressInfos.city}/${addressInfos.UF}`}</p>
        <p>{`${addressInfos.CEP}`}</p>
      </div>
    </div>
  );
};

const contacts = (contactList) => {
  return (
    <div className="flex flex-col h-full w-full items-center justify-center">
      {contactList.map((items) => {
        return (
          <div
            key={items.icon}
            className="flex flex-row w-full items-center justify-center grid grid-rows-auto grid-cols-4 md:grid-cols-3"
          >
            <div className="w-full flex justify-end">
              <img src={icons[items.icon]} alt={items.icon} />
            </div>
            <div className="col-span-3 md:col-span-2 w-full">
              <p className="text-fourth font-poppins font-semibold ml-2 text-sm lg:text-md">
                {items.contact}
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const socialMedias = (socialMedia) => {
  return (
    <div className="flex flex-row h-full w-full items-center justify-center">
      {socialMedia.map((items) => {
        return (
          <div
            key={items.icon}
            className="flex items-center justify-center mx-2"
          >
            <a href={items.href} target="_black">
              <img src={icons[items.icon]} alt={items.icon} />
            </a>
          </div>
        );
      })}
    </div>
  );
};

const footer = () => {
  const { footer } = config;
  const { logo, addressInfos, contactList, socialMedia, href } = footer;
  const currentYear = new Date().getFullYear();
  return (
    <footer className="h-[65vh] w-full bg-tertiary mt-10" name={href}>
      <div className="flex w-full h-full grid grid-cols-auto grid-rows-auto sm:grid-cols-3 sm:grid-rows-auto">
        {address(logo, addressInfos)}
        {contacts(contactList)}
        {socialMedias(socialMedia)}
      </div>
      <div className="flex w-full h-10 border-t border-t-1 border-fourth bg-tertiary items-center justify-center font-poppins">
        <p className="text-fourth text-sm">
          {`©${currentYear} designed by `}
          <a
            className="text-fourth "
            target="_black"
            href="https://github.com/HugoDaudt"
          >
            {` @HugoDaudt`}
          </a>
        </p>
      </div>
    </footer>
  );
};

export default footer;
