import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/css/index.css";
import Root from "./views/root";
import Header from "./components/Header";
import PracticeAreas from "./views/practiceAreas";
import Objective from "./views/objective";
import QualityReference from "./views/qualityReference";
import Schedule from "./views/schedule";
import Footer from "./views/footer";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <Header />
    <Root />
    <PracticeAreas />
    <Objective />
    <QualityReference />
    <Schedule />
    <Footer />
  </>
);
