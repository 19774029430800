import React, { useState, useEffect } from "react";
import config from "../assets/index.json";
import logoImage from "../assets/img/camillo-adv-logo.png";

import { Link } from "react-scroll";

const Header = () => {
  const { office, header } = config;
  const { name } = office;
  const { officeDescription, navigation } = header;
  const [hidden, setHidden] = useState(false);

  useEffect(() => {
    let prevScrollPos = window.scrollY;

    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      setHidden(prevScrollPos < currentScrollPos);
      prevScrollPos = currentScrollPos;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const pageScroll = (pageView) => {
    let currentScrollPos = window.scrollY;
    let viewportHeight = window.innerHeight;

    if (pageView === "practiceAreas") {
      viewportHeight = window.innerHeight * 2;
    } else if (pageView === "aboutUs") {
      viewportHeight = window.innerHeight * 3;
    } else if (pageView === "aboutUs") {
      viewportHeight = window.innerHeight * 6;
    }

    if (viewportHeight > currentScrollPos) {
      return 0;
    } else {
      return -90;
    }
  };

  return (
    <header
      className={`fixed z-1000 top-0 left-0 w-full bg-tertiary p-4 text-light transition-transform border-b border-b-1 border-fourth ${
        hidden ? "-translate-y-full" : "translate-y-0 "
      }`}
    >
      <div className="grid grid-rows-1 grid-cols-3 sm:grid-cols-2 flex">
        <div className="grid grid-cols-2 grid-rows-1 flex flex-row h-full md:w-40 w-20">
          <div className="w-full h-full">
            <img src={logoImage} alt={name} />
          </div>
          <title className="flex text-center w-full h-full font-CormorantGar text-xs md:text-2xl">
            <b>{officeDescription}</b>
          </title>
        </div>
        <div className="flex space-x-3 md:space-x-8 font-poppins h-full items-center justify-end sm:justify-center col-span-2 sm:col-span-1">
          {navigation.map((item) => {
            return (
              <Link
                spy={true}
                active="active"
                offset={pageScroll(item.href)}
                smooth={true}
                duration={1000}
                key={item.name}
                to={item.href}
                className="text-xs md:text-xl"
              >
                {item.name}
              </Link>
            );
          })}
        </div>
      </div>
    </header>
  );
};

export default Header;
