import React from "react";
import config from "../assets/index.json";

const icons = {
  greetingIcon: require("../assets/svg/greetingIcon.svg").default,
  computerIcon: require("../assets/svg/computerIcon.svg").default,
  bagIcon: require("../assets/svg/bagIcon.svg").default,
};

const myReferences = (icon, title, text) => {
  return (
    <div className="flex flex-col p-4">
      <div className="w-full flex flex-col items-center justify-center mb-0">
        <img
          className="h-16 w-16 lg:h-24 lg:w-24"
          src={icons[icon]}
          alt="familyIcon"
        />
        <div className="w-full flex items-center justify-center">
          <b className="text-primary text-center font-Cormorant text-lg lg:text-2xl border-b border-b-2 border-primary sm:w-72">
            {title}
          </b>
        </div>
      </div>
      <div className="font-poppins text-sm lg:text-lg h-full mt-5 text-fourth sm:p-4 text-center sm:text-left">
        <p>{text}</p>
      </div>
    </div>
  );
};

const qualityReference = () => {
  const { qualityReference } = config;
  const { title, content } = qualityReference;
  return (
    <div className="h-[90vh] sm:h-[100vh] w-full mt-20 bg-tertiary">
      <div>
        <div className="flex justify-center items-center w-full h-20">
          <b className="h-8 sm:h-14 text-fourth font-Cormorant text-2xl sm:text-4xl border-b border-b-2 border-primary">
            {title}
          </b>
        </div>
        <div className="grid grid-cols-3 grid-rows-auto flex flex-col h-full sm:mt-10">
          {content.map((items) => {
            return (
              <div key={items.title}>
                {myReferences(items.img, items.title, items.content)}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default qualityReference;
