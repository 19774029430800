import React from "react";
import config from "../assets/index.json";

const icons = {
  familyIcon: require("../assets/svg/family-icon.svg").default,
  pantheonIcon: require("../assets/svg/pantheon-icon.svg").default,
  suitIcon: require("../assets/svg/suit-icon.svg").default,
  contractIcon: require("../assets/svg/contract-icon.svg").default,
  heritageIcon: require("../assets/svg/heritage-icon.svg").default,
  homeIcon: require("../assets/svg/home-icon.svg").default,
};

const myPracticesAreas = (icon, title, text) => {
  return (
    <div className="flex flex-col p-4">
      <div className="w-full flex flex-col items-center justify-center mb-0">
        <img
          className="h-16 w-16 lg:h-24 lg:w-24"
          src={icons[icon]}
          alt="familyIcon"
        />
        <div className="w-full flex items-center justify-center">
          <b className="text-primary text-center font-Cormorant text-lg lg:text-2xl border-b border-b-2 border-primary sm:w-72">
            {title}
          </b>
        </div>
      </div>
      <div className="font-poppins text-sm lg:text-lg h-full mt-5 p-2 sm:p-4">
        <ul className="font-poppins">
          {text.map((item) => {
            return <li key={item}>&bull;{item}</li>;
          })}
        </ul>
      </div>
    </div>
  );
};

const PracticeAreas = () => {
  const { practiceAreas } = config;
  const { title, content, href } = practiceAreas;
  return (
    <div className="w-full h-full bg-white flex flex-col" name={href}>
      <div className="flex justify-center items-center w-full h-20">
        <b className="h-8 sm:h-14 text-secondary font-Cormorant text-2xl sm:text-4xl border-b border-b-2 border-primary">
          {title}
        </b>
      </div>
      <div className="grid grid-cols-2 grid-rows-auto sm:grid-cols-3 sm:grid-rows-auto flex flex-col h-full sm:mt-10">
        {content.map((items) => {
          return (
            <div key={items.title}>
              {myPracticesAreas(items.img, items.title, items.content)}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PracticeAreas;
